import React from 'react';

import styles from './MenuLink.module.scss'

const MenuLink = ({
    children,
    id,
    closeMenu
  }: React.PropsWithChildren<{ id: string, closeMenu?: () => void }>) => {
    const handleClick = () => {
      const section = document.getElementById(id) as Element;
      const y = section.getBoundingClientRect().top + window.pageYOffset + -75;
      closeMenu && closeMenu()
      window.scrollTo({ top: y, behavior: 'smooth' });
    };

    return (
      <button onClick={handleClick} className={styles.link}>
        {children}
      </button>
    );
  };

  export { MenuLink };