import React from 'react';

import { Paragraph } from '../typography';

import { TimelineContent } from '.';

import styles from './Timeline.module.scss';
import { motion } from 'framer-motion';

const TimelineBlock = ({
  orientation,
  employer,
  roles,
  shortExp,
  startYear,
  endYear,
  Icon,
  animationProps
}: TimelineContent & { animationProps: any; orientation: 'left' | 'right' }) => {
  return (
    <div className={styles.timelineBlock}>
      <motion.div
        whileHover={{ transform: 'scale(1.25)' }}
        {...animationProps}
        className={`${styles.card} ${styles[orientation]}`}
      >
        <div className={styles.role}>{roles.latestRole}</div>
        <div className={styles.employer}>{employer}</div>
        <div className={styles.experience}>
          <Paragraph>{shortExp}</Paragraph>
        </div>
        <p className={styles.mobileDate}>
          {startYear} - {endYear ? endYear : 'present'}
        </p>
      </motion.div>
      <motion.div {...animationProps} className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </motion.div>
      <div className={styles[`${orientation}Date`]}>
        <p>
          {startYear} - {endYear ? endYear : 'present'}
        </p>
      </div>
    </div>
  );
};

export { TimelineBlock };
