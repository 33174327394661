import React from 'react';

import { TimelineBlock } from './TimelineBlock';
import { motion } from 'framer-motion';

import styles from './Timeline.module.scss';

export type TimelineContent = {
  employer: string;
  startYear: number | string;
  endYear?: number | string;
  experience: string;
  roles: {
    previousRoles?: string[];
    latestRole: string;
  };
  technologiesUsed: string[];
  shortExp: string;
  Icon: any;
};

const item = {
  hidden: { transform: 'scale(0)' },
  show: { transform: 'scale(1)' }
};

const vrAnimation = {
  hidden: { height: 0 },
  show: { height: '100%' }
};

const animationProps = {
  transition: { duration: 1, type: 'spring', bounce: 0.25 },
  whileInView: 'show',
  variants: item,
  initial: 'hidden',
  viewport: { once: true }
};

const Timeline = (props: { content: TimelineContent[] }) => {
  return (
    <div className={styles.timeline}>
      <motion.div className={styles.container}>
        {props.content.map((job, i) => {
          const orientation = i % 2 === 0 ? 'left' : 'right';
          return (
            <motion.div>
              <TimelineBlock animationProps={animationProps} {...job} orientation={orientation} />
            </motion.div>
          );
        })}
        <motion.div
          transition={{ duration: 8, type: 'spring' }}
          whileInView='show'
          variants={vrAnimation}
          initial='hidden'
          viewport={{ once: true }}
          className={styles.vr}
        />
      </motion.div>
    </div>
  );
};

export { Timeline };
