import React from 'react';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailIcon from '@mui/icons-material/Mail';

import styles from './Contact.module.scss';
import { ContactForm } from '../../components/contactForm';

const Contact = () => {
  return (
    <div id='Contact' className={styles.container}>
      <div className={styles.form}>
        <div className={styles.formWrapper}>
          <ContactForm />
        </div>
        <div className={styles.text}>
          <h1>Get in touch</h1>
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.iconWrapper}>
          <a target='_blank' href='https://uk.linkedin.com/in/luke-s-384705175' rel='noreferrer'>
            <LinkedInIcon className={styles.icon} />
          </a>
          <a href='mailto:luke@lukespeirs.co.uk'>
            <MailIcon className={styles.icon} />
          </a>
        </div>
      </div>
    </div>
  );
};

export { Contact };
