import React from 'react';

import styles from './Input.module.scss';

type TextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type InputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputProps = {
  title: string;
  inputProps: InputType | TextareaProps;
  type?: 'textarea' | 'input';
  error?: string; // New error prop
};

export const Input: React.FC<InputProps> = ({ title, type = 'input', inputProps, error }) => {
  const id = `${type}-input-${title}`;

  return (
    <div className={styles.inputWrapper}>
      <label htmlFor={id} className={styles.title}>
        {title}
      </label>
      {type === 'textarea' ? (
        <textarea id={id} className={`${styles.input} ${error ? styles.error : ''}`} {...(inputProps as TextareaProps)} />
      ) : (
        <input id={id} className={`${styles.input} ${error ? styles.error : ''}`} {...(inputProps as InputType)} />
      )}
      <span className={error ? styles.errorMessage : styles.hiddenError}>{error}</span> {/* Display error message */}
    </div>
  );
};
