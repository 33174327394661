import { ReactComponent as AWS } from './AWS.svg';
import { ReactComponent as Azure } from './Azure.svg';
import { ReactComponent as Cypress } from './Cypress.svg';
import { ReactComponent as Docker } from './Docker.svg';
import { ReactComponent as Git } from './Git.svg';
import { ReactComponent as HTML } from './HTML.svg';
import { ReactComponent as Jest } from './Jest.svg';
import { ReactComponent as JS } from './JS.svg';
import { ReactComponent as Node } from './Node.svg';
import { ReactComponent as Postgres } from './Postgres.svg';
import { ReactComponent as React } from './React.svg';
import { ReactComponent as SCSS } from './SCSS.svg';
import { ReactComponent as TS } from './TS.svg';

export const StackIcons = [HTML, JS, TS, React, Node, SCSS, Jest, Cypress, Git, Docker, Postgres, AWS, Azure]
