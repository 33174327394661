import React from 'react';

import { About } from './pages/about';
import { LandingV2 } from './pages/landing/v2/index';
import { Experience } from './pages/experience';
import { Topbar } from './components/topbar';
import { Contact } from './pages/contact';
import { ToastProvider } from './context/ToastContext';
import { motion } from 'framer-motion';

import styles from './App.module.scss';

function App() {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      document.body.style.overflow = 'scroll';
    }, 7000);
  }, []);

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <ToastProvider>
      <motion.div className={styles.App}>
        <Topbar />
        <LandingV2 />
        <About />
        <Experience />
        <Contact />
      </motion.div>
    </ToastProvider>
  );
}

export default App;
