import React from 'react';

import { StackIcons } from '../../../assets/images/icons';
import { motion } from 'framer-motion';

import styles from './LandingV2.module.scss';

const Icons = () => {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.375,
        delayChildren: 2
      }
    }
  };

  const item = {
    hidden: {
      transform: 'scale(0)'
    },
    show: {
      transform: 'scale(1)',
      transition: { type: 'spring', bounce: 0.65 }
    }
  };

  return (
    <motion.div viewport={{ once: true }} initial='hidden' whileInView='show' className={styles.right} variants={container}>
      {StackIcons.map((Icon) => (
        <motion.div variants={item}>
          <Icon className={styles.icon} />
        </motion.div>
      ))}
    </motion.div>
  );
};

export { Icons };
