import React from 'react';

import { Paragraph } from '../../components/typography';

import { motion } from 'framer-motion';

import styles from './About.module.scss';

const About = (_props: {}) => {
  return (
    <div className={styles.about} id="About">
      <motion.div
        className={styles.box}
        animate={{
          clipPath: [
            'circle(30% at 50% 50%)',
            'circle(20% at 50% 50%)',
            'circle(20% at 0% 0%)',
            'circle(20% at 75% 56%)',
            'circle(20% at 22% 89%)',
            'circle(20% at -30% 20%)',
            'circle(30% at 50% 50%)'
          ]
        }}
        transition={{
          duration: 3,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatDelay: 1
        }}
      >
        <div className={styles.heading}>About</div>
      </motion.div>
      <div className={styles.text}>
        <Paragraph>
          Hello, I'm Luke, a seasoned software developer specialising in frontend development. With
          a focus on technologies like React, TypeScript, and CSS, I've spent the past five years
          honing my skills to create captivating and user-friendly frontend solutions.
        </Paragraph>
        <Paragraph>
          Frontend development is my passion, and I thrive on designing visually stunning and
          intuitive user interfaces. Leveraging tools like React, I excel at building dynamic and
          responsive applications that provide exceptional user experiences across various devices
          and platforms.
        </Paragraph>
      </div>
    </div>
  );
};

export { About };
