import React from 'react';

import { H1Props, H2Props, ParagraphProps } from './Typography.types';

import styles from './Typography.module.scss';

const H1 = (
  props: H1Props
) => {
  return <h1 {...props}>{props.children}</h1>;
};

const H2 = (
  props: H2Props
) => {
  return <h2 className={styles.H2} {...props}>{props.children}</h2>;
};

const Paragraph = (
  props: ParagraphProps
) => {
  return <p className={styles.paragraph} {...props}>{props.children}</p>;
};

export { H1, H2, Paragraph };
