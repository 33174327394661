import React from 'react';

import { ReactComponent as Logo } from '../../../assets/images/LS-logo-software.svg';
import { Title } from './Title';
import { Icons } from './Icons';

import { motion } from 'framer-motion';

import styles from './LandingV2.module.scss';

export const LandingV2 = () => {

  return (
    <div className={styles.landing} id="Intro">
      <div className={styles.left}>
        <Title />
      </div>
      <motion.div
        initial={{ y: '-25vh', rotate: 180, opacity: 0, scale: 0.5 }}
        animate={{ y: 0, opacity: 1, rotate: 0, scale: 1 }}
        transition={{ duration: 1 }}
        className={styles.center}
      >
        <Logo className={styles.logo} />
      </motion.div>
        <Icons />
    </div>
  );
};
