import React from 'react';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SchoolIcon from '@mui/icons-material/School';
import { motion } from 'framer-motion';
// @ts-ignore
import cv from '../../assets/data/Luke_Speirs_CV-2024.pdf';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import { Timeline } from '../../components/timeline';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';

import styles from './Experience.module.scss';

const experience = [
  {
    employer: 'Constructor Labs',
    startYear: 'September 2018',
    endYear: 'November 2018',
    experience: `At Constructor Labs I underwent an intensive 12-week software development bootcamp, where I immersed myself in a comprehensive curriculum focused on modern web technologies. Under the guidance of experienced instructors, I acquired proficiency in key practises and frameworks, including JavaScript, React, Node.js, and React Native. This immersive learning experience provided me with a solid foundation in software development principles and best practices.

  As part of the bootcamp, I participated in a final project where I collaborated with a team of peers to design and implement a real-world application. This project not only allowed me to apply the skills and knowledge gained throughout the program but also sharpened my ability to work effectively in a collaborative environment. The completion of this project demonstrated my capability to deliver high-quality solutions while adhering to project requirements and deadlines.`,
    shortExp:
      'At Constructor Labs, I underwent a 12-week software development bootcamp, immersing myself in modern web technologies like JavaScript, React, Node.js, and React Native. Guided by experienced instructors, I gained proficiency in key practices and frameworks, culminating in a collaborative final project that demonstrated my ability to deliver high-quality solutions while working effectively with peers.',
    roles: {
      latestRole: '12 Week Code Camp'
    },
    technologiesUsed: ['JavaScript', 'React', 'Node.js', 'SQL', 'React Native'],
    Icon: SchoolIcon
  },
  {
    employer: 'Adludio',
    startYear: 'January 2019',
    endYear: 'January 2021',
    experience: `At Adludio, I progressed from being inexperienced to a confident, knowledgeable, and dependable Developer. As I advanced, I was given increased responsibility, which included working on both front-end and back-end code, building and maintaining APIs, learning TypeScript, refactoring repositories entirely from JS to TS, liaising with the design and product teams, overseeing the task board, implementing new features, and setting up new projects from scratch. Additionally, I gained valuable experience and understanding of working in an agile environment.`,
    shortExp:
      'At Adludio, I evolved from an inexperienced developer to a confident and dependable one, handling front-end and back-end tasks, API development, TypeScript adoption, repository refactoring, collaboration with design and product teams, task board management, feature implementation, and initiating projects from inception. I also gained significant experience in agile methodologies.',
    roles: {
      latestRole: 'Junior Full Stack Developer',
      previousRoles: []
    },
    technologiesUsed: [
      'JavaScript',
      'TypeScript',
      'React',
      'React Native',
      'Node.js',
      'Docker',
      'AWS',
      'SQL',
      'GraphQL',
      'SCSS',
      'Storybook'
    ],
    Icon: AutoGraphOutlinedIcon
  },
  {
    employer: 'Adludio',
    startYear: 'January 2021',
    endYear: 'March 2022',
    experience: `After being promoted to Mid-Level Developer I continued to work with React, Typescript and Node on a daily basis, had the opportunity to gain some experience with GraphQL and spent some time learning more about and working with Docker.`,
    shortExp: `After being promoted to Mid-Level Developer I continued to work with React, Typescript and Node on a daily basis, had the opportunity to gain some experience with GraphQL and spent some time learning more about and working with Docker.`,
    roles: {
      latestRole: 'Mid Level Full Stack Developer',
      previousRoles: ['Junior Full Stack Developer']
    },
    technologiesUsed: [
      'JavaScript',
      'TypeScript',
      'React',
      'React Native',
      'Node.js',
      'Docker',
      'AWS',
      'SQL',
      'GraphQL',
      'SCSS',
      'Storybook'
    ],
    Icon: WorkOutlineOutlinedIcon
  },

  {
    employer: 'Simplyhealth',
    startYear: 'March 2022',
    experience: `I joined Simplyhealth as a Frontend Developer and initially worked on revamping their sign up process. Once this work was complete I started to take on more responsibility, including:
    
• Being responsible for the coordination of all frontend and API releases for the New Business squad.
• Starting and chairing the React Chapter - A group consisting of React devs from different areas of the business who would get together once a month to discuss anything React/JS/TS related and to share knowledge.
• Working closely with senior stakeholders to understand business requirements and help translate these into technical requirements for the rest of the team.
• Planning and documenting technical specifications for new features.
• Assisting in the hiring process of new developers which included attending technical interviews, creating a test so that we can gauge their level of expertise, preparing and asking technical questions and giving my opinion on interviewees.
    
During my time at Simplyhealth I also gained experience in automation testing with Cypress. This included writing many tests, implementing Cypress into some of our code bases and generating HTML and JSON reports on test runs.
    `,
    shortExp:
      'As a Frontend Developer at Simplyhealth, I revamped the sign-up process before taking on additional responsibilities, including coordinating frontend and API releases, leading the React Chapter, collaborating with stakeholders on technical requirements, documenting specifications, and contributing to the hiring process. Additionally, I gained experience in automation testing with Cypress, writing tests, integrating Cypress into code bases, and generating test reports.',
    roles: {
      latestRole: 'Frontend Developer'
    },
    technologiesUsed: [
      'JavaScript',
      'TypeScript',
      'React',
      'React Native',
      'Node.js',
      'Cypress',
      'Jest',
      'Azure',
      'Terraform',
      'SCSS'
    ],
    Icon: HealthAndSafetyOutlinedIcon
  }
];

const Experience = () => {
  return (
    <div className={styles.experience} id='Experience'>
      <div className={styles.header}>
        <h1>Experience</h1>
      </div>
      <Timeline content={experience} />
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className={styles.downloadButton}
        transition={{ type: 'spring', stiffness: 400, damping: 10 }}
      >
        <a download href={cv}>
          <div className={styles.download}>
            <CloudDownloadIcon className={styles.icon} /> Download full CV
          </div>
        </a>
      </motion.button>
    </div>
  );
};

export { Experience };
