import React from 'react';

import { motion } from 'framer-motion';

import styles from './LandingV2.module.scss';

const Title = () => {
  const nameContainer = {
    show: {
      transition: {
        delayChildren: 2,
        staggerChildren: 0.1
      }
    }
  };

  const nameLetters = {
    hidden: {
      y: '-25vh',
      opacity: 0
    },
    show: {
      y: 0,
      transition: { type: 'spring', bounce: 0.65 },
      opacity: 1
    }
  };

  const roleContainer = {
    show: {
      transition: {
        delayChildren: 5,
        staggerChildren: 0.1
      }
    }
  };

  const roleLetters = {
    hidden: {
      opacity: 0
    },
    show: {
      opacity: 1
    }
  };
  return (
    <>
      <motion.div
        initial='hidden'
        whileInView='show'
        variants={nameContainer}
        style={{ display: 'flex' }}
        viewport={{ once: true }}
      >
        {'Luke Speirs'.split('').map((letter) => (
          <motion.h1 variants={nameLetters} className={styles.name}>
            {letter === ' ' ? <span>&nbsp;</span> : letter}
          </motion.h1>
        ))}
      </motion.div>
      <motion.div
        initial='hidden'
        whileInView='show'
        variants={roleContainer}
        style={{ display: 'flex' }}
        viewport={{ once: true }}
      >
        {'Frontend Developer'.split('').map((letter) => (
          <motion.p variants={roleLetters} className={styles.jobTitle}>
            {letter === ' ' ? <span>&nbsp;</span> : letter}
          </motion.p>
        ))}
      </motion.div>
    </>
  );
};

export { Title };
