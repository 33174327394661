import React from 'react';

import { Paragraph } from '../typography';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ProgressBar } from '../progressBar';
import { MenuLink } from '../menuLink';
// import MailIcon from '@mui/icons-material/Mail';
import { ReactComponent as Logo } from '../../assets/images/LS.svg'

import { AnimationProps, motion } from 'framer-motion';

import styles from './Topbar.module.scss';

const container: AnimationProps['variants'] = {
  show: {
    transition: {
      staggerChildren: 0.25,
      delayChildren: 2
    }
  }
};

const item = {
  hidden: {
    transform: 'scale(0)'
  },
  show: {
    transform: 'scale(1)',
    transition: { type: 'spring', bounce: 0.65 }
  }
};

const Topbar = () => {
  return (
    <div className={styles.topbar}>
      <div className={styles.content}>
        <motion.div
          initial='hidden'
          whileInView='show'
          variants={container}
          className={styles.icons}
        >
          <motion.div variants={item}>
            {/* <a target='_blank' href='https://uk.linkedin.com/in/luke-s-384705175' rel='noreferrer'>
              <LinkedInIcon className={styles.icon} />
            </a> */}
            <Logo className={styles.icon} />
          </motion.div>
          {/* <motion.div variants={item}>
            <a href='mailto:luke@lukespeirs.co.uk'>
              <MailIcon className={styles.icon} />
            </a>
          </motion.div> */}
        </motion.div>
        <motion.div
          initial='hidden'
          whileInView='show'
          variants={container}
          className={styles.links}
        >
          {['Intro', 'About', 'Experience', 'Contact'].map((id) => {
            return (
              <motion.div variants={item}>
                <MenuLink id={id} key={id}>
                  <Paragraph>{id}</Paragraph>
                </MenuLink>
              </motion.div>
            );
          })}
        </motion.div>
      </div>
      <ProgressBar />
    </div>
  );
};

export { Topbar };
