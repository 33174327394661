import React, { useState, useRef } from 'react';
import { Input } from '../input/Input';
import { motion } from 'framer-motion';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { useToast } from '../../hooks/useToast';
import styles from './ContactForm.module.scss';

const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email.toLowerCase());
};

const validateGBPhoneNumber = (number: string): boolean => {
  const re = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;
  return re.test(number);
};

const validateName = (name: string): boolean => {
  const re = /^[A-Za-z\s-]+$/;
  return re.test(name) && name.trim().length >= 3;
};

const validateField = (name: string, value: string): string => {
  if (!value.trim()) {
    return 'This field is required';
  }

  switch (name) {
    case 'name':
      return !validateName(value)
        ? 'Name must be at least 3 characters long and contain only letters, spaces, and hyphens.'
        : '';
    case 'emailAddress':
      return !validateEmail(value) ? 'Invalid email address.' : '';
    case 'contactNumber':
      return !validateGBPhoneNumber(value)
        ? 'Invalid GB phone number. Must be in the format +44 7123 456 789 or 07123 456 789.'
        : '';
    case 'message':
      return value.trim().length < 10 ? 'Message must be at least 10 characters long.' : '';
    default:
      return '';
  }
};

const ContactForm: React.FC = () => {
  const [contactInfo, setContactInfo] = useState({
    name: '',
    emailAddress: '',
    contactNumber: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    emailAddress: '',
    contactNumber: '',
    message: ''
  });

  const { addToast } = useToast();
  const formRef = useRef<HTMLFormElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setContactInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value)
    }));
  };

  const validateAllFields = (): boolean => {
    const newErrors = Object.keys(contactInfo).reduce((acc, key) => {
      const error = validateField(key, contactInfo[key as keyof typeof contactInfo]);
      acc[key as keyof typeof contactInfo] = error;
      return acc;
    }, {} as { [key in keyof typeof contactInfo]: string });

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const isFormValid = (): boolean => {
    return (
      Object.values(errors).every((error) => error === '') &&
      Object.values(contactInfo).every((value) => value.trim() !== '')
    );
  };

  const handleSend = (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateAllFields()) {
      addToast('Please fix the errors in the form before submitting.', 'error');
      return;
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_KEY as string,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
        formRef.current as HTMLFormElement,
        { publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY }
      )
      .then(
        (_result: EmailJSResponseStatus) => {
          setContactInfo({
            name: '',
            emailAddress: '',
            contactNumber: '',
            message: ''
          });
          setErrors({
            name: '',
            emailAddress: '',
            contactNumber: '',
            message: ''
          });
          addToast('Message sent!', 'success');
        },
        (error) => {
          addToast(error.text, 'error');
          console.log(error.text);
        }
      );
  };

  const buttonProps = isFormValid()
    ? {
        whileHover: { scale: 1.1 },
        whileTap: { scale: 0.9 },
        transition: { type: 'spring', stiffness: 400, damping: 10 }
      }
    : {};

  return (
    <form ref={formRef} className={styles.contactForm} onSubmit={handleSend}>
      <Input
        title='Name'
        inputProps={{
          name: 'name',
          onChange: handleChange,
          value: contactInfo.name
        }}
        error={errors.name}
      />
      <Input
        title='Email address'
        inputProps={{
          name: 'emailAddress',
          onChange: handleChange,
          value: contactInfo.emailAddress
        }}
        error={errors.emailAddress}
      />
      <Input
        title='Contact number'
        inputProps={{
          name: 'contactNumber',
          onChange: handleChange,
          value: contactInfo.contactNumber
        }}
        error={errors.contactNumber}
      />
      <Input
        title='Message'
        type='textarea'
        inputProps={{
          name: 'message',
          onChange: handleChange,
          value: contactInfo.message
        }}
        error={errors.message}
      />
      <div className={styles.buttonWrapper}>
        <motion.button { ...buttonProps} className={isFormValid() ? styles.sendButton : styles.disabledButton}>
          Send
        </motion.button>
      </div>
    </form>
  );
};

export { ContactForm };
